import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "BANGRS2",
  "date": "2021-12-16"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`BANGRS2`}</h1>
    <h2>{`Summary`}</h2>
    <p>{`one of 7 discs mostly randomly generated from
`}<a parentName="p" {...{
        "href": "https://twitter.com/ringtonebangers"
      }}>{`Ringtone Bangers`}</a>{`
clips as they have uploaded to google drive. track layout determined via
script. recorded in SP MONO.`}</p>
    <p>{`the first track was hardwired to be the classic Nokia tone.`}</p>
    <p>{`the intent of this disc was to push a minidisc session to the limits for
track capacity and title naming. at 254 tracks, that gives each track seven
characters to hold the track name. track 0 is actually the free block map,
and the disc title...`}</p>
    <h2>{`Tracks`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=pe1ZXh5_wk4"
        }}><inlineCode parentName="a">{`INGTONE`}</inlineCode>{`	Nokia 3310 - Classic Monophonic RINGTONE (2017).mp4`}</a></li>
      <li parentName="ol"><inlineCode parentName="li">{`80IDAWN`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/48 Sony Ericsson W380i - Dawn.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IHIGHER`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/348d Sony Ericsson K750i - Higher.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`_SIGNAL`}</inlineCode>{`	Season 3 (days 182-300`}<em parentName="li">{` March 2021-July 2021)/199n Kyocera Kona - Poppin`}</em>{` Signal.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0AMINOR`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/197n LG S5100 - A minor.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CEPTRUM`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/265n Android 2.3 Gingerbread - Sceptrum.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`000MOON`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/391d Motorola A1000 - Moon.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LAQRIFF`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/342d Motorola Q - Riff.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0GROOVE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/149d Motorola A1000 - Groove.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LLREMIX`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/63 Discord - Incoming Call (Remix).mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HTSWING`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/365n Samsung Galaxy S9 - Midnight Swing.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OGREMIX`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/273d Jamster - Crazy Frog Remix.mpg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LOWFISH`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/272n LG KG240 - Blowfish.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EPOLISH`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/265d HTC Ozone - Polish.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EGROOVE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/105n Nokia 7270 - Re-groove.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`USRETRO`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/378d Vestel Venus - Retro.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`QBOOGIE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/383n Windows Mobile (downloadable) - BBQ Boogie.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SOLARIS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/187d HTC Ozone - Solaris.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TARTUP3`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380dd KDE 3.3 - Startup 3.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`JUPITER`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/270d Alcatel OT-660 - Jupiter.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CHINESE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/206n1 Nokia Tune Remake Audiodraft Sound Design Contest - NOKIA CHINESE.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`5RUNWAY`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/350d Nokia N85 - Runway.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`10GROWL`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/107n Android 1.0 - Growl.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CSLEAZY`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/10 Motorola E1000 - Mcsleazy.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CEPARTY`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/72 Samsung Galaxy S6 - Dance Party.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NE7PURE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/08 Windows Phone 7 - Pure.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IRDLOOP`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/164n Android 1.0 - Bird Loop.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RELAXED`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/343d Sony Ericsson G705 - Relaxed.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`95GLINT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/216n Nokia N95 - Glint.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ANDWINE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/108n LG KG280 - Cigar And Wine.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`BAGITUP`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/11 BenQ-Siemens S68 - Bag it up.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SONMARS`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/374n T-Mobile Sidekick 3 - Chimps on Mars.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`310KICK`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/340d Nokia 3310 - Kick.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TORYLAP`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/326n Google Sounds 2.2 - Victory Lap.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LITHAZE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/338d Motorola E815 - Moonlit Haze.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LLATION`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/240n Motorola ROKR EM35 - Oscillation.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GTONE17`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/277n LG enV2 - Ringtone 17.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`K3SHINE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/164d Motorola KRZR K3 - Shine.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ECLOUDS`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/310d Sony Ericsson P1i - Into the clouds.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OUNGING`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/276d Nokia 7370 - Lounging.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`2PEPPER`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/205n Nokia N72 - Pepper.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`STARTUP`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380de Mandriva Linux 2006 - Startup.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CENSION`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/27 Motorola RIZR Z3 - Ascension.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NKY_ALL`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/302d Android 1.5 Cupcake - Funk Y_all.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NGARING`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/172n Samsung Galaxy Advance - Ring A Ring.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`UNNYDAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/103d Samsung C5212 - Sunny day.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`UNLEASH`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/110d Motorola Q9c - Unleash.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CGIMLET`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/331n HTC Magic - Gimlet.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`AMBIENT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/292n Motorola RAZR V3i - Ambient.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LATINUM`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/325d Android 4.0 Ice Cream Sandwich - Platinum.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`URNMEON`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/173n LG Lotus - Turn Me On.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NODANCE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/192d Samsung SGH-P250 - Techno dance.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OCOLATE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/115n Windows Mobile Ring Tone Pack 3 - Hot Chocolate.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RW5COOL`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/217n Motorola ROKR W5 - Cool.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ELSGOOD`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/320n Samsung Galaxy S20 - Feels Good.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ERWORLD`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/209d Samsung Galaxy S III - Underwater world.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DYNAMIC`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/224n Motorola RAZR V3xx - Dynamic.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`WFLAKES`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/113d Windows Mobile Ring Tone Pack 3 - Snow Flakes.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TJINGLE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/62 T-Jingle (2006).mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DWESTST`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/287d Panasonic G60 - JCD West St..wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`AINLINK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/294d Motorola V600 - Chain Link.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`60GREEN`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/313d Alcatel OT-660 - Green.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`THEEDGE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/246d Motorola E1070 - Off The Edge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`YS8AQUA`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/198n Samsung Galaxy S8 - Aqua.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LSTREET`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/332d Sony Ericsson P1i - Hill street.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`3XPERIA`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/91 Sony Xperia Z3 - xperia.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GHTLIFE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/220n Motorola E365 - Night Life.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DEMO043`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/221d Yamaha SMAF (downloadable) - MA Sound Demo043.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`8COFFEE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/236n Samsung Galaxy S8 - Coffee.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EANKUMA`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/297n Android 4.2 Jelly Bean - Kuma.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EDITION`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/195n Android 1.5 Cupcake - Champagne Edition.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ALIENTE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/75 Windows Mobile (downloadable) - Ballroom Caliente.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LYGHOST`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/370n Android 0.9 - Friendly Ghost.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NCOFIRE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/158d Windows Mobile Ring Tone Pack 2 - Flamenco Fire.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TERNOON`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/135n LG KG320 - Lazy Afternoon.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ERUNWAY`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/389n LG dLite - Runway.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NEALARM`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/328n iPhone - Alarm.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LLBLEND`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/152d Android 1.0 - Curve Ball Blend.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`1ORANGE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/67 Xiaomi Mi A1 - Orange.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0DAMMAR`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/327n HTC 10 - Dammar.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EFRIDAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/144d HTC Ozone - Friday.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`55BRILL`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/257d Nokia E55 - Brill.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CLAPPER`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/346d Nokia N78 - Clapper.mp4`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`70TWIRL`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/150n Motorola E1070 - Twirl.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HEHEIST`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/381n Windows Mobile Ring Tone Pack 7 - The Heist.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`POPXRAY`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/382n Alcatel M_POP - X-Ray.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OCKSOLO`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/364d Motorola W180 - Rock Solo.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0CIRCUS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/188d Motorola RIZR Z10 - Circus.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`380GLOW`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/176d Nokia 7380 - Glow.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`1RISING`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/204d Windows Mobile Extended Audio Pack 1 - Rising.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0PLANET`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/339n Samsung Galaxy S10 - Planet.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IGHTWAY`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/263n Yamaha SMAF (downloadable) - Straight Way.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`97BEATS`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/33 Motorola V197 - Beats.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DEMO044`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/244d Yamaha SMAF (downloadable) - MA Sound Demo044.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`KYSHORT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/251n Motorola RAZR V3 - Spooky (short).flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`PDRAGON`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/165d Nokia 7710 - Snapdragon.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`9FRIDAY`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/39 Samsung Galaxy S9 - Friday.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CHRONOS`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/53 Motorola A1000 - Chronos.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`AYDREAM`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/262d Windows Mobile Ring Tone Pack 5 - Day Dream.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGTO~1`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/266n Nokia Audio themes - Jazz ringtone.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`UNSHINE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/368d Samsung Galaxy Note 7 - Sunshine.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`THATWAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/145n Nokia N73 - Stay that way.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Z3TEMPO`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/201d Motorola RIZR Z3 - Tempo.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DOSCOPE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/92n Motorola E365 - Kaleidoscope.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RAINBOW`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/192n Nokia 113 - Triple rainbow.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TICTONE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/28 Samsung Galaxy S5 - Mystic Tone.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ARNIVAL`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/284d Samsung Galaxy S20 - Carnival.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DEMO041`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/190n Yamaha SMAF (downloadable) - MA Sound Demo041.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`MINATED`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/22 Android - Terminated.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0LOUNGE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/281d Motorola A1000 - Lounge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TTAHAVE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/294n Nokia 7370 - Gotta have.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DAWNING`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/37 Nokia 8800 Sirocco Edition - Dawning.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`FASHION`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/104n Motorola RAZR V3i - Fashion.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ENALINE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/195d Motorola KRZR K1 - Adrenaline.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ILLASKY`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/54 LG KG240 - Vanilla Sky.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGWALK`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/302n LG Lotus - Evening Walk.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`FUNTIME`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/339d LG CE110 - Fun-Time.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0SUNSET`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/160n Motorola A1000 - Sunset.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TELLITE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/94n Samsung Galaxy S10 - Satellite.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`BEANICE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/101d Android 1.0 - Caribbean Ice.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SEASIDE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/26 iOS 7 - By The Seaside.m4r`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`USCIOUS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/188n Motorola W490 - Luscious.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INLOOPS`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/74 Motorola RAZR V3c - Latin Loops.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ELODY12`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/124n Doro PhoneEasy 410gsm - Melody 12.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CECRAFT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/221n Samsung Galaxy S7 - Spacecraft.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LOTHEME`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/223n Windows Mobile (downloadable) - Halo Theme.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ASTPACE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/155d Motorola Q9m - Fast Pace.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`VETHEME`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/320d Motorola A1000 CD - Love Theme.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ANORIFF`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/238d iPhone - Piano Riff.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`5MINGLE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/125d Nokia N85 - Mingle.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ECIRCUS`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/362n Motorola A3100 - Space Circus.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`REVERIE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/145d Samsung Ego - Reverie.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NGTONE7`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/23 Windows 7 Beta - Ringtone 7.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INAMASK`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/367d Motorola V191 (China) - Mask.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DEMO055`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/282d Yamaha SMAF (downloadable) - MA Sound Demo055.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ENDANCE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/317n HP iPAQ Glisten - Dance.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ILOUNGE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/211n Motorola RAZR V3i - Lounge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`WFLAK~1`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/115d Nokia 6681 - Snowflakes.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGTO~2`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/319d Nokia Audio themes - Golf ringtone.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0BRUNCH`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/208n Sony Ericsson T700 - Brunch.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RALPARK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/224d LG Sentio - Central Park.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SEVILLE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/226d Android 1.5 Cupcake - Seville.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GTRICKS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/267n Nokia 6101b - Playing tricks.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IGHTIDE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/373d Samsung Galaxy S III - High tide.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`3STREET`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/227n Maxo PHONE TONES PACK #3 - Street.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ASTWARD`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/343n Alcatel OT-708X - Eastward.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EAKDOWN`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/377n Motorola RAZR V3i - Breakdown.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ACKDIVE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/230n Samsung Jack - Dive.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ONDDAWN`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/322d HTC Touch Diamond - Dawn.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Q9HGLOW`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/357n Motorola Q9h - Glow.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`KIDRIVE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/337n HTC One X - Alki Drive.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`YFUTURE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/260n Sony Ericsson Vivaz - X-ray future.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EDUBLIN`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/363d T-Mobile Sidekick Slide - Dublin.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OADTRIP`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/146d Android 1.5 Cupcake - Road Trip.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CLOUNGE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/316n HTC Magic - Lounge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HOLIDAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/116n Samsung Galaxy S8 - Holiday.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IMETREE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/90 Nokia 6680 - Lime tree.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`FORWARD`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/303n T-Mobile Shadow - Moving Forward.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`YLIGHTS`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/174d Samsung Galaxy S8 - City Lights.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`PLUCKER`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/123n Android 1.0 - Beat Plucker.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ILKYWAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/180n Android 2.0 Eclair - Silky Way.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NFORMER`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/247n HTC One M9 - Informer.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LLYWOOD`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/208d Android 1.5 Cupcake - Bollywood.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LATIN~1`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/272d Motorola Q9m - Platinum.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGTO~3`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/251d Nokia Audio themes - Car videoringtone.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EBOTTLE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/153n Samsung Galaxy Chat - Wine bottle.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HESTAGE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/158n Samsung Galaxy S6 - On the Stage.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RBREATH`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/212d Samsung Ego - Hold your breath.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ACKHOLE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/387d Nokia Tones - Black Hole.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Q9HNOVA`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/301n Motorola Q9h - Nova.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OOKBACK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/185n Samsung B5702 DuoS - Look Back.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NEWYEAR`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/122n Windows Mobile (downloadable) - Sweet New Year.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`REDIBLE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/280d Nokia 6270 - Elecredible.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0SOREAL`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/245n LG KG280 - So Real.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`H1OHRID`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/31 Essential PH-1 - Ohrid.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RASONIC`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/83 Motorola E398 - Ultra Sonic.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CICONIC`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/278d Motorola Q9c - Iconic.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0ILATIN`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/92d Sony Ericsson K800i - Latin.3gp`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`90BIKER`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/119d Motorola C390 - Biker.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`00BC110`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/142d Nokia 8800 - Bc110.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LEFEVER`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/355d LG KG800 Chocolate - Jungle Fever.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`0VISION`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/233d Windows Mobile 6.0 - Vision.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ISMIRON`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/281n Nokia 7500 Prism - Iron.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ERATION`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/170n Samsung Galaxy S III Mini - U generation.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IGSOLAR`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/182d Motorola M702ig - Solar.3gp`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IDHENRY`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/344n Gionee GN305 - Horrid Henry.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`KIATUNE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/126n Nokia 6270 - Nokia tune.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`THEBEAT`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/117d Windows Mobile Ring Tone Pack 8 - Carol Of The Beat.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`YLOUNGE`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/213n Android 1.0 - Loopy Lounge.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ONLIGHT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/207n HTC Droid DNA - Moon Light.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TIMEPAD`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/358d Alcatel OT-660 - Time Pad.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`BERBAND`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/38 Windows Mobile (downloadable) - Rubber Band.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LKCYCLE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/341d Maxo Phone Tones Pack #5 - Walk Cycle.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NEWFLOW`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/14 Motorola RAZR V3i - New Flow.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LIDSODA`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/107d Yamaha SMAF (downloadable) - Solid Soda.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SIONMA3`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/374d Panasonic X300 - Fusion ma-3.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RETLIFE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/166d Samsung SGH-F480 Tocco - Secret Life.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DESTINY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/111n Nokia 6681 - Destiny.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`2RHODES`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/95n HTC E-Club Ring Tone Pack 2 - Rhodes.MP3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LSIGNAL`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/36 Motorola W490 - Digital Signal.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TARTUP1`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380db KDE 3.3 - Startup 1.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`5ROCKME`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/235n Motorola ROKR EM35 - Rock Me.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ITRAILS`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/378n Nokia 5140i - Trails.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`START~1`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380da KDE 3.0 - Startup.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HARDMIX`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/288d Windows Mobile Ring Tone Pack 5 - Hard Mix.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ULTTONE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/342n LG Venus - VZW Default Tone.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IGHTOWL`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/175n iOS 7 - Night Owl.m4r`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`6CANVAS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/243n Nokia E66 - Canvas.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HICBABA`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/312n Samsung Galaxy Pocket - Chic baba.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`RO2STEP`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/284n HTC Hero - 2 Step.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LENUEVO`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/174n Windows Mobile (downloadable) - Nuevo.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`E10AQUA`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/213d HTC Sense 1.0 - Aqua.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OPENING`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/129n iOS 7 - Opening.m4r`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`IDPLAYA`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/41 Android - Playa.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SEDRIVE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/335d fusoxide_s Ringtones Vol. 1 - House Drive.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`DEMO021`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/293d Yamaha SMAF (downloadable) - MA Sound Demo021.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ERSURGE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/134n Motorola E1070 - Power Surge.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GONAVIS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/202n Android 2.3 Gingerbread - Argo Navis.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SAFFRON`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/154d HTC Ozone - Saffron.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`GETAWAY`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/161d Google Pixel 4 - Getaway.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`EFLIGHT`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/136n Android 2.0 Eclair - Free Flight.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ANDMILK`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/112d Windows Mobile Ring Tone Pack 8 - Cookies And Milk.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`UNBEAMZ`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/232n Sony Ericsson Vivaz - Sunbeamz.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NMYSOUL`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/157n Sony Ericsson T700 - In my soul.m4a`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`SODAPOP`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/248d Nokia 6300 (newer firmware) - Soda pop.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`REAMYOU`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/356d Samsung SGH-i700 - Dream You.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`AYNIGHT`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/152n Motorola E398 - Saturday night.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OVIOLET`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/271d Nokia Oro - Violet.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`20DJPOP`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/52 Alcatel OT-5020D - JPop.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ISLANDS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/268n Motorola A920 - The Islands.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LESTONE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/80 HTC Sense 1.0 - Cobblestone.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LDSTYLE`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/96n Motorola RIZR Z3 - Wild Style.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`INGSTAR`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/185d Samsung Galaxy S10 - Shooting Star.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`2COVERT`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/219n HTC HD2 - Covert.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`BOOKEM_`}</inlineCode>{`	Season 2 (days 92-181`}<em parentName="li">{` December 2020-March 2021)/143n HTC HD7 - Book Em`}</em>{`.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TEPPERS`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/246n Panasonic G50 - Steppers.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ICDISCO`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/390d Microsoft Office clipart - Generic Disco.wav`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LAWLESS`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/332n Motorola Q9h - Flawless.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ORSOLAR`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/155n Nokia 9500 Communicator - Solar.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ISTFLOW`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/348n Samsung Ch@t 335 - Moist flow.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`NIVERSE`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/45 Motorola SLVR L6g (Chinese version) - Little Universe.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TARTUP2`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/380dc KDE 3.3 - Startup 2.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`KCHIMER`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/71 Windows Mobile Ring Tone Pack - Chimer.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`00BELL6`}</inlineCode>{`	Season 1 (days 1-91_ September 2020-December 2020)/50 Samsung SGH-X100 - Bell 6.flac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ICKITUP`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/163n Samsung Galaxy S9 - Pick It Up.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`98FLUID`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/260d Motorola E398 - Fluid.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HETRICK`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/255d Nokia 6500 - The trick.aac`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`WEETPIE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/334d LG Viewty Smart - Sweet Pie.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`HORIZON`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/128n Samsung Galaxy S II - Over the horizon.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ISHLINE`}</inlineCode>{`	Season 4 (days 301-present_ July 2021-present)/315n Google Sounds 2.2 - Finish Line.ogg`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`90WAVES`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/135d Motorola C390 - Waves.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`OLAMOTO`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/127n Motorola - Moto.mp3`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ORGANIC`}</inlineCode>{`	Season 3 (days 182-300_ March 2021-July 2021)/191d HTC Touch - Organic.wma`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`LLYDOIT`}</inlineCode>{`	Season 2 (days 92-181_ December 2020-March 2021)/109n BenQ-Siemens S68 - You really do it.wav`}</li>
    </ol>
    <h2>{`Mixing/Sourcing Notes`}</h2>
    <p>{`source to the script is available `}<a parentName="p" {...{
        "href": "https://gist.github.com/arrjay/3837fb1eca6d87f61d54741f9cf93a8d"
      }}>{`HERE`}</a>{`.
no promises it will run anywhere or is fit for any purpose.`}</p>
    <p>{`ringtone bangers archives are available via google drive, as linked in
`}<a parentName="p" {...{
        "href": "https://twitter.com/ringtonebangers/status/1444785594941550596"
      }}>{`their tweet`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      